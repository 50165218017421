import { QueryPrevalidateAdagioSiteNameDomainArgs } from '@src/app/generated/graphql';
import { gql } from 'apollo-angular';

interface Result {
  prevalidateAdagioSiteNameDomain: boolean;
}

export const PREVALIDATE_ADAGIO_SITE_NAME_DOMAIN_QUERY = gql<Result, QueryPrevalidateAdagioSiteNameDomainArgs>`
  query validate($bidderId: Int, $name: String, $domain: String) {
    prevalidateAdagioSiteNameDomain(bidderId: $bidderId, name: $name, domain: $domain)
  }
`;
